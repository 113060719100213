<template>
  <div class="form-wrapper" style="width:70%">
    <div class="login-form login-signin">
      <div class="mb-2 mb-lg-2">
        <h1 class="font-size-h1" style="font-weight:bold;color:#000">
          Sign in
        </h1>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <span>{{ $store.state.loginError }}</span>
          <!-- <div class="" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div> -->
        </div>
        <div v-if="verifyOtpSec">
          <div class="row">
            <input ref="otpInput0" v-model="otp[0]" type="number" @keydown="handleKeyDown($event,0)" @input="changeinput($event,0)" aria-label="first digit" />
            <input ref="otpInput1" v-model="otp[1]" type="number" @keydown="handleKeyDown($event,1)" @input="changeinput($event,1)" aria-label="second digit" />
            <input ref="otpInput2" v-model="otp[2]" type="number" @keydown="handleKeyDown($event,2)" @input="changeinput($event,2)" aria-label="third digit" />
            <input ref="otpInput3" v-model="otp[3]" type="number" @keydown="handleKeyDown($event,3)" @input="changeinput($event,3)" aria-label="fourth digit" />
          </div>
          <!-- <b-form-group style="color:#000;"
          label="OTP"
        >
          <b-form-input
            placeholder="OTP"
            class="form-control form-control-solid h-auto py-5 px-6"
            v-model="otp"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
        </b-form-group> -->
        </div>

        <b-form-group
        v-else
          id="example-input-group-1" style="color:#000;"
          label="Email"
          label-for="example-input-1"
        >
          <b-form-input
            placeholder="Email"
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            @input="handelclickinput"
            :state="validateState('email') && !errorMessage"
            :formatter="formatter"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
           {{ errorMessage? errorMessage:'Email is required and a valid email address.' }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- <b-form-group
          id="example-input-group-2"
          label="Password"
          label-for="example-input-2"
        >
          <b-form-input
            placeholder="Password"
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            @input="handelclickinput"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group> -->
        <!-- <div>
                <span class="forgot">Forgot Password?</span>
            </div> -->
        <div class="form-group">
          <button
            style="font-size: 16px;"
            :disabled="loginDisable"
            ref="kt_login_signin_submit"
            class="btn btn-dark form-control"
          >
            {{verifyOtpSec ?  'Verify OTP' : 'Send OTP'}}
          </button>
        </div>
        <div class="form-group text-center">
         <!--<a
            class="text-capitalize"
            style="font-size: 16px;"
            href="http://join.cpxaffiliate.com/users/forgot_password"
          >
            Forgot Password
          </a>  -->
          <!-- <router-link class="text-capitalize"
            style="font-size: 16px;" :to="{ name: 'forgot_password' }">
            Forgot Password?
          </router-link>  -->
        </div>
        <!-- <div class="form-group d-flex flex-wrap justify-content-center align-items-center">
            Don`t have one ?
                <a href="#" style="font-size: 16px;" class="text-dark-100 sign-up-link ml-2" id="kt_login_forgot">
                  Sign Up
                </a>
                 </div> -->
        <!--end::Action-->
      </b-form>

          <!-- <div class="google-btn" @click="loginGoogle">
              <div class="google-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48">
              <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
              </svg>
              </div>
              <p class="btn-text"><b>Sign in with google</b></p>
            </div> -->
        <div class="d-flex justify-content-center btns input-group">
          <!-- <a class="google-btn" :href="url">
              <div class="google-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48">
              <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
              </svg>
              </div>
              <p class="btn-text"><b>Sign in with google</b></p>
            </a> -->
            <button
            style="font-size: 16px;"
            @click="handleSingnUp"
          >
           Sign up As Affiliate
          </button>
        </div>
      <!--end::Form-->
    </div>
    <!--begin::Content header-->
    <!-- <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <span class="font-weight-bold font-size-3 text-dark-60">
            Don't have an account yet?
        </span>
        <router-link class="font-weight-bold font-size-3 ml-2" :to="{ name: 'register' }">
            Sign Up!
        </router-link>
    </div> -->
    <!--end::Content header-->

    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT,GOOGLE_LOGIN } from "@/core/services/store/auth.module";
import axios from 'axios'

import ApiService from "../../../core/services/api.service";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        // email: "affiliate@coupon.ae",
        // password: "A3v4b5n",
        // email: "misschill.blog@gmail.com",
        // password: "Emilyinparis@33",
        email: "",
        password: "",
      },
      url:'',
      otp:['','','',''],
      verifyOtpSec:false,
      loginDisable:false,
      flag: 0,
      errorMessage:''
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      // password: {
      //   required,
      //   minLength: minLength(3),
      // },
    },
  },
  mounted(){
    // http://localhost:8082/signin#access_token=&token_type=Bearer&expires_in=3599&scope=profile%20https://www.googleapis.com/auth/userinfo.profile
  },
  methods: {
    handleSingnUp(){
      window.open('https://forms.fillout.com/t/4MzjCrpDHGus')
    },
    // loginGoogle() {
    //   fetch('https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&include_granted_scopes=true&response_type=code&redirect_uri=http%3A//localhost:8080/signin&client_id=887797868713-umk76qetd7gkuhk15k8thitd4liqrr5t.apps.googleusercontent.com')
    //   .then(response => {
    //     if (response.ok) {
    //       // Handle successful response
    //       return response.json(); // or response.text() depending on the response type
    //     } else {
    //       // Handle error response
    //       throw new Error('Network response was not ok.');
    //     }
    //   })
    //   .then(data => {
    //     // Handle data returned from the server
    //     console.log(data);
    //   })
    //   .catch(error => {
    //     // Handle any errors that occurred during the fetch
    //     console.error('Fetch error:', error);
    //   });

    // },
//     console.clear();
// let inputs = document.querySelectorAll("input");
// let values = Array(4);
// let clipData;
// inputs[0].focus();

// inputs.forEach((tag, index) => {
//   tag.addEventListener('keyup', (event) => {
//     if(event.code === "Backspace" && hasNoValue(index)) {
//       if(index > 0) inputs[index - 1].focus();
//     }

//     //else if any input move focus to next or out
//     else if (tag.value !== "") {
//       (index < inputs.length - 1) ? inputs[index + 1].focus() : tag.blur();
//     }

//     //add val to array to track prev vals
//     values[index] = event.target.value;
//   });

//   tag.addEventListener('input', () => {
//     //replace digit if already exists
//     if(tag.value > 10) {
//       tag.value = tag.value % 10;
//     }
//   });

//   tag.addEventListener('paste', (event) => {
//     event.preventDefault();
//     clipData = event.clipboardData.getData("text/plain").split('');
//     filldata(index);
//   })
// })
changeinput(e,index){
  this.otp[index] = e.data && e.data.length > 0 ?e.data[0]:''
  if( this.otp[index] && index!=3){
    this.$refs[`otpInput${index+1}`].focus();
  }
  else if(!this.otp[index] && index!==0){
    this.$refs[`otpInput${index-1}`].focus();
  }
},
  handleKeyDown(e,index){
    if (e.keyCode === 8 && this.otp[index].length === 0 && index > 0) {
          // Move focus to the previous input field
          this.$refs[`otpInput${index - 1}`].focus();
        }
  },
    formatter(value) {
        return value.toLowerCase()
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    handelclickinput(){
     this.loginDisable = false
    },
    onSubmit() {
      this.loginDisable = true;
      this.flag = 1;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        console.log(this.$v.form)
        return;
      }

      const email = this.$v.form.email.$model;
      const otp =this.otp.join('')
      // const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay

      // send login request
      if(this.verifyOtpSec){
      try{
        this.$store
          .dispatch(LOGIN, {
            email,
            otp,
          })
          // go to which page after successfully login
          .then((res) => {
            this.verifyOtpSec = false
            this.$router.push({
              name: "dashboard",
            })
          })
      } catch(err) {
        console.log(err,"err=");
      }
      }else{
        this.errorMessage=''
      ApiService.post("/send_otp", {email:email}).then(
        (response) => {
          if(response.data.status == 'success'){
            this.verifyOtpSec = true
          }
        })
        .catch((error)=>{
          if(error.response.data && error.response.data.error && error.response.data.error.message){
            this.errorMessage = error.response.data.error.message
          }
        })
      }
      setTimeout(() => {
        this.loginDisable = false;
      }, 2000);
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
  },
  watch: {
    errors(newQuestion, oldQuestion) {
     // console.log(newQuestion,"newQuestion===");
    }
  },
  mounted() {
/** To mounted Google Sign in

var currentUrl = window.location.href;
    if(currentUrl.includes('#')){
      var newUrl = currentUrl.replace('#', '?');
      window.location.href = newUrl;
    }else{
      if(this.$route.query.access_token){
        axios.get('https://www.googleapis.com/userinfo/v2/me', {
          headers: {
            Authorization: `Bearer ${this.$route.query.access_token}`
          }
        })
                .then(response => {
                  if(response.data.email){
                    let email
                    if(localStorage.dummyEmail ? (localStorage.dummyEmail == '0' ? false : true)  : false ){
                      email = 'labinot.hasani@hotmail.com'
                    }else{
                      email = response.data.email
                    }
                    let access_token = this.$route.query.access_token
                    this.$store
                            .dispatch(GOOGLE_LOGIN, {
                              email,
                              access_token,
                            })
                            .then((res) => {
                              console.log(res)
                              this.$router.push({
                                name: "dashboard",
                              })
                            })
                    // ApiService.get(`/google_verification?code=${this.$route.query.access_token}&email=`).then(
                    //     (response) => {
                    //         console.log(response.data,"dsatda=[--=-=]")
                    //     })
                    //     .catch(error => {
                    //       console.error(error);
                    //     });
                  }else{
                    console.log(response.data,"data-=-=-");
                  }
                })
                .catch(error => {
                  console.error(error);
                });
      }
    }*/
    window.localStorage.removeItem('affiliate_id');
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('user');
    if (localStorage.getItem("affiliate_id")) {
      this.$router.push("dashboard");
    }
    var redirect_uri = window.location.origin + '/signin'
     this.url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&include_granted_scopes=true&response_type=token&redirect_uri=${redirect_uri}&client_id=887797868713-umk76qetd7gkuhk15k8thitd4liqrr5t.apps.googleusercontent.com`
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style scoped>

.google-btn {
  cursor: pointer;
  width: 184px;
  height: 42px;
  background-color:  #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  position: relative;
}

.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}

.btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}

.google-btn:hover {
  box-shadow: 0 0 6px  #4285f4;
}

.google-btn:active {
  background: #1669F2;
}
.form-control.form-control-solid {
  background-color: #fff2f2;
  border-color: #f3fcfe;
  border-radius: 5px;
  font-size: 16px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #37a4cb !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #37a4cb !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #37a4cb !important;
}

.sign-up-link {
  color: #000;
  font-weight: bold !important;
}
.head {
  color: black;
  font-weight: 700;
}
.forgot {
  float: right;
  font-weight: 600;
  cursor: pointer;
}

.btn {
  margin-top: 30px;
  border-radius: 5px;
}
.btn-dark {
  background: #000;
  border-color: #000;
}

form.form > div:last-child {
    order: 4;
    text-align: right !important;
}

form.form {
    display: flex;
    flex-direction: column;
}

form.form > div:nth-child(4) {
    order: 5;
}
form.form > div:last-child > a {
    color: #000;
    font-weight: bold;
    font-size: 14px !important;
}

.form-group label {
    font-size: 13px !important;
    font-weight: bold !important;
    color: #000 !important;
}
.row {
  margin: auto; /* center horizontally */

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width:100%;
}

input[type=number] {
  margin: 0.5rem;
  padding: 0.5rem;
  max-width: 2rem;
  text-align: center;
border: 1px solid black
}

/* remove the up/down spinner in number input
   source - https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media(max-width:767px){
    .form-wrapper{
      width:100% !important;
    }
    button.btn.btn-dark.form-control {
     height: 50px;
   }
}

.input-group.btns  {
    background: #FF6633;
    border-radius: 8px;
    color: #000;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    height: 45px;
}
</style>